import Loadable from 'components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const List = Loadable(lazy(() => import('./List')));
const Create = Loadable(lazy(() => import('./Create')));

const couponRoutes = [
  { path: '/coupons/list', element: <List />, auth: authRoles.admin },
  { path: '/coupons/create', element: <Create />, auth: authRoles.admin },
];

export default couponRoutes;
