import Loadable from 'components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const List = Loadable(lazy(() => import('./List')));
const View = Loadable(lazy(() => import('./View')));

const transactionsRoutes = [
  { path: '/transactions', element: <List />, auth: authRoles.admin },
  {
    path: '/transactions/:transactionId',
    element: <View />,
    auth: authRoles.admin,
  },
];

export default transactionsRoutes;
