import { Box } from '@mui/material';
import MUIDataTable from 'mui-datatables';

export const PaginationDataTable = ({
  data,
  columns,
  onClick,
  pagination = true,
  actions = null,
}) => {
  const handleItemClick = (rowData, rowMeta, e) => {
    e.preventDefault();

    onClick && onClick(data[rowMeta.dataIndex]);
  };

  return (
    <Box width='100%' overflow='auto'>
      {data.length > 0 ? (
        <MUIDataTable
          data={data}
          columns={[
            ...columns,
            ...((actions && [
              {
                name: 'actions',
                label: 'Actions',
                options: {
                  customBodyRenderLite: (dataIndex) => {
                    const item = data[dataIndex];

                    return actions && actions(item);
                  },
                },
              },
            ]) ||
              []),
          ]}
          options={{
            filterType: 'textField',
            selectableRowsHideCheckboxes: true,
            onRowClick: handleItemClick,
          }}
        />
      ) : (
        <div style={{ textAlign: 'center' }}>
          <h4>SORRY!</h4>
          <p>
            Database is empty! We did not find any information for you at this
            moment.
          </p>
        </div>
      )}
    </Box>
  );
};
