import Api from './Api';

export const getAllImproves = async () =>
  await Api.http({
    method: 'get',
    url: `admin/improve-score/all`,
  });

export const getImproveById = async (id) =>
  await Api.http({
    method: 'get',
    url: `admin/improve-score/${id}`,
  });

export const updateImprove = async (id, formData) =>
  await Api.http({
    method: 'post',
    url: `admin/improve-score/${id}`,
    headers: {
      'Content-Type': 'multipart/form-data; charset=utf-8;',
    },
    body: formData,
  });

export const createImprove = async (formData) =>
  await Api.http({
    method: 'post',
    url: `admin/improve-score/store`,
    body: formData,
  });

export const deleteImproveImages = async (id, images) =>
  await Api.http({
    method: 'delete',
    url: `admin/improve-score/${id}/images`,
    body: { images },
  });
