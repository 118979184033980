import Loadable from 'components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const List = Loadable(lazy(() => import('./List')));
const Edit = Loadable(lazy(() => import('./Edit')));
const Create = Loadable(lazy(() => import('./Create')));


const improveScoreRoutes = [
  { path: '/improve-score', element: <List />, auth: authRoles.admin },
  { path: '/improve-score/create', element: <Create />, auth: authRoles.admin },
  { path: '/improve-score/:improveId', element: <Edit />, auth: authRoles.admin },
];

export default improveScoreRoutes;
