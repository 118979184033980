import React from 'react';
import { Button, Grid, styled } from '@mui/material';
import { SimpleCard } from 'components';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Span } from 'components/Typography';
import { updateUserData } from 'api';
import useAuth from 'hooks/useAuth';
import { NotificationManager } from 'react-notifications';

const Container = styled('div')(({ theme }) => ({
  margin: '30px',
  [theme.breakpoints.down('sm')]: { margin: '16px' },
  '& .breadcrumb': {
    marginBottom: '30px',
    [theme.breakpoints.down('sm')]: { marginBottom: '16px' },
  },
}));

export const ChangePassword = () => {
  const { logout } = useAuth();

  const [state, setState] = React.useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  React.useEffect(() => {
    ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
      if (value !== state.newPassword) {
        return false;
      }

      return true;
    });

    return () => ValidatorForm.removeValidationRule('isPasswordMatch');
  }, [state.newPassword]);

  const handleSubmit = (event) => {
    event.preventDefault();

    updateUserData({ password: state.newPassword })
      .then(() => {
        NotificationManager.success(
          'Your password has been successfully changed, you will be logged out for security reasons.',
          'Success',
          2000
        );
        
        logout();
      })
      .catch(console.error);
  };

  const handleChange = (event) => {
    event.preventDefault();
    setState({ ...state, [event.target.name]: event.target.value });
  };

  return (
    <Container>
      <SimpleCard title='Change Password'>
        <ValidatorForm onSubmit={handleSubmit} instantValidate>
          <Grid container spacing={3} sx={{ justifyContent: 'center' }}>
            <Grid item lg={7}>
              <TextValidator
                type='password'
                name='newPassword'
                id='standard-basic'
                value={state.newPassword || ''}
                onChange={handleChange}
                label='New Password'
                validators={[
                  'required',
                  'minStringLength:8',
                  'maxStringLength:20',
                ]}
                errorMessages={[
                  'This field is required',
                  'Password must contain more than 8 characters',
                  'Password cannot contain more than 20 characters',
                ]}
                fullWidth
              />
            </Grid>

            <Grid item lg={7}>
              <TextValidator
                type='password'
                name='confirmPassword'
                id='standard-basic'
                value={state.confirmPassword || ''}
                onChange={handleChange}
                label='Confirm Password'
                validators={['isPasswordMatch', 'required']}
                errorMessages={[
                  'The password does not match the new password',
                  'This field is required',
                ]}
                fullWidth
              />
            </Grid>

            <Grid item lg={7}>
              <Button
                color='primary'
                variant='contained'
                type='submit'
                fullWidth
              >
                <Span sx={{ textTransform: 'uppercase' }}>Save</Span>
              </Button>
            </Grid>
          </Grid>
        </ValidatorForm>
      </SimpleCard>
    </Container>
  );
};
