import Api from './Api';

export const getCurrentUser = async () =>
  await Api.http({
    method: 'get',
    url: `/users/profile`,
  });

export const loginUser = async (formData) => {
  const res = await Api.http({
    method: 'post',
    url: `/auth/admin/login`,
    body: formData,
  });

  localStorage.setItem('adminToken', res.data.tokens.accessToken);

  return res;
};

export const updateUserData = async (formData) =>
  await Api.http({
    method: 'post',
    url: `/users/profile`,
    body: formData,
  });