export const navigations = [
  { name: 'Dashboard', path: '/dashboard', icon: 'dashboard' },

  { label: 'General', type: 'label' },
  {
    name: 'Users',
    icon: 'person',
    badge: { color: 'secondary' },
    children: [
      { name: 'List All', path: '/users', count: 'users', iconText: 'A' },
      {
        name: 'Verified',
        path: '/users/verified',
        count: 'verified',
        iconText: 'V',
      },
      {
        name: 'Not Verified',
        path: '/users/not-verified',
        count: 'notVerified',
        iconText: 'N',
      },
      {
        name: 'Ready for Review',
        path: '/users/for-review',
        count: 'forReview',
        iconText: 'R',
      },
    ],
  },
  {
    name: 'Score Details',
    icon: 'analytics',
    badge: { color: 'secondary' },
    children: [
      { name: 'List All', path: '/score-details', iconText: 'A' },
      { name: 'Create', path: '/score-details/create', iconText: 'B' },
    ],
  },
  {
    name: 'Improve Score',
    icon: 'analytics',
    badge: { color: 'secondary' },
    children: [
      { name: 'List All', path: '/improve-score', iconText: 'A' },
      { name: 'Create', path: '/improve-score/create', iconText: 'B' },
    ],
  },
  {
    name: 'Transactions',
    icon: 'money',
    badge: { color: 'secondary' },
    children: [{ name: 'List All', path: '/transactions', iconText: 'A' }],
  },
  {
    name: 'Coupons',
    icon: 'confirmation_number',
    badge: { color: 'secondary' },
    children: [
      { name: 'List All', path: '/coupons/list', iconText: 'A' },
      { name: 'Create new', path: '/coupons/create', iconText: 'B' },
    ],
  },
];
