import Api from './Api';

export const connectPayment = async () =>
  await Api.http({
    method: 'post',
    url: `/payment/connect`,
  });

export const checkoutSession = async (formData) =>
  await Api.http({
    method: 'post',
    url: `/payment/stripe/checkout`,
    body: formData,
  });

export const getTransactions = async () =>
  await Api.http({
    method: 'get',
    url: `/admin/transactions`,
  });

export const getTransactionById = async (id) =>
  await Api.http({
    method: 'get',
    url: `/admin/transactions/${id}`,
  });
