import Loadable from 'components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const List = Loadable(lazy(() => import('./List')));
const Edit = Loadable(lazy(() => import('./Edit')));
const Create = Loadable(lazy(() => import('./Create')));

const scoreDetailsRoutes = [
  { path: '/score-details', element: <List />, auth: authRoles.admin },
  { path: '/score-details/create', element: <Create />, auth: authRoles.admin },
  { path: '/score-details/:detailId', element: <Edit />, auth: authRoles.admin },
];

export default scoreDetailsRoutes;
