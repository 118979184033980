import Loadable from 'components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';
import { ChangePassword } from 'views/account/ChangePassword';

const Analytics = Loadable(lazy(() => import('./Analytics')));

const dashboardRoutes = [
  { path: '/dashboard', element: <Analytics />, auth: authRoles.admin },
  { path: '/change-password', element: <ChangePassword />, auth: authRoles.admin },
];

export default dashboardRoutes;
