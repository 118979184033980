import {
  Box,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { useState } from 'react';

const StyledTable = styled(Table)(() => ({
  whiteSpace: 'normal',
  tableLayout: 'auto',

  '& td': {
    whiteSpace: 'normal',
  },

  '& thead': {
    '& tr': { '& th': { paddingLeft: 0, paddingRight: 0 } },
  },
  '& tbody': {
    '& tr': { '& td': { paddingLeft: 0 } },
  },
}));

const PaginationTable = ({ data, columns, onClick, actions = null }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleItemClick = (e, item) => {
    e.preventDefault();

    onClick && onClick(item);
  };

  return (
    <Box width='100%' overflow='auto'>
      <StyledTable>
        <TableHead>
          <TableRow>
            {columns?.map((column, i) => (
              <TableCell align='left' key={column + i}>
                {column}
              </TableCell>
            ))}

            {actions && <TableCell align='right'>Actions</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {data
            ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            ?.map((item, index) => (
              <TableRow
                key={index}
                onClick={(e) => handleItemClick(e, item)}
                style={onClick && { cursor: 'pointer' }}
              >
                {columns.map((column, i) => (
                  <TableCell align='left' key={column + i}>
                    {item[column.toLowerCase()]}
                  </TableCell>
                ))}

                {actions && (
                  <TableCell align='right'>
                    {actions.map((Action, index) => (
                      <Action item={item} />
                    ))}
                  </TableCell>
                )}
              </TableRow>
            ))}
        </TableBody>
      </StyledTable>

      <TablePagination
        sx={{ px: 2 }}
        page={page}
        component='div'
        rowsPerPage={rowsPerPage}
        count={data?.length}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[5, 10, 25]}
        onRowsPerPageChange={handleChangeRowsPerPage}
        nextIconButtonProps={{ 'aria-label': 'Next Page' }}
        backIconButtonProps={{ 'aria-label': 'Previous Page' }}
      />
    </Box>
  );
};

export default PaginationTable;
