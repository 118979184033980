import { Navigate } from 'react-router-dom';
import MatxLayout from './components/MatxLayout/MatxLayout';
import AuthGuard from 'auth/AuthGuard';

import dashboardRoutes from 'views/dashboard/DashboardRoutes';
import usersRoutes from 'views/users/UsersRoutes';
import scoreDetailsRoutes from 'views/score-details/ScoreDetailsRoutes';
import NotFound from 'views/sessions/NotFound';
import sessionRoutes from 'views/sessions/SessionRoutes';
import transactionsRoutes from 'views/transactions/TransactionsRoutes';
import improveScoreRoutes from 'views/improve-score/ImproveScoreRoutes';
import couponRoutes from 'views/coupons/CouponRoutes';

const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      ...dashboardRoutes,
      ...usersRoutes,
      ...scoreDetailsRoutes,
      ...improveScoreRoutes,
      ...transactionsRoutes,
      ...couponRoutes,
    ],
  },
  ...sessionRoutes,
  { path: '/', element: <Navigate to='dashboard' /> },
  { path: '*', element: <NotFound /> },
];

export default routes;
