import Api from './Api';

export const getUserInfo = async (userId) =>
  await Api.http({
    method: 'get',
    url: userId ? `/admin/users/${userId}` : `/users/profile`,
  });

export const getUsers = async () =>
  await Api.http({
    method: 'get',
    url: '/admin/users/all',
  });

export const getUsersForReview = async () =>
  await Api.http({
    method: 'get',
    url: '/admin/users/reviews',
  });

export const getVerifiedUsers = async () =>
  await Api.http({
    method: 'get',
    url: '/admin/users/verified',
  });

export const getNotVerifiedUsers = async () =>
  await Api.http({
    method: 'get',
    url: '/admin/users/not-verified',
  });

export const getUserTransactions = async (userId) =>
  await Api.http({
    method: 'get',
    url: `/admin/users/${userId}/transactions`,
  });

export const updateUser = async (userId, formData) =>
  await Api.http({
    method: 'post',
    url: `/admin/users/${userId}`,
    body: formData,
  });

export const getUserScores = async (userId) =>
  await Api.http({
    method: 'get',
    url: `/admin/users/score/${userId}`,
  });

export const getUserScoreHistory = async (userId) =>
  await Api.http({
    method: 'get',
    url: `/admin/users/score/${userId}/history`,
  });

export const updateUserScore = async (scoreId, formData) =>
  await Api.http({
    method: 'post',
    url: `/admin/users/score/${scoreId}/update`,
    body: formData,
  });

export const updateUserScores = async (userId, formData) =>
  await Api.http({
    method: 'post',
    url: `/admin/users/score/${userId}`,
    body: formData,
  });
