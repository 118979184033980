import Api from './Api';

export const getAllScoreDetails = async () =>
  await Api.http({
    method: 'get',
    url: `admin/score-details/all`,
  });

export const getScoreDetails = async (id) =>
  await Api.http({
    method: 'get',
    url: `admin/score-details/${id}`,
  });

export const updateScoreDetails = async (id, formData) =>
  await Api.http({
    method: 'post',
    url: `admin/score-details/${id}`,
    body: formData,
  });

export const createScoreDetails = async (formData) =>
  await Api.http({
    method: 'post',
    url: `admin/score-details/create`,
    body: formData,
  });

export const getAllScoreGroups = async () =>
  await Api.http({
    method: 'get',
    url: '/score-details/groups',
  });

export const getAllScoreCategories = async () =>
  await Api.http({
    method: 'get',
    url: 'admin/score-details/categories',
  });
