import Loadable from 'components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const List = Loadable(lazy(() => import('./List')));
const ListOfVerified = Loadable(lazy(() => import('./ListOfVerified')));
const ListOfNotVerified = Loadable(lazy(() => import('./ListOfNotVerified')));
const UnderReview = Loadable(lazy(() => import('./UnderReview')));
const Profile = Loadable(lazy(() => import('./Profile')));

const usersRoutes = [
  { path: '/users', element: <List />, auth: authRoles.admin },
  {
    path: '/users/verified',
    element: <ListOfVerified />,
    auth: authRoles.admin,
  },
  {
    path: '/users/not-verified',
    element: <ListOfNotVerified />,
    auth: authRoles.admin,
  },
  {
    path: '/users/for-review',
    element: <UnderReview />,
    auth: authRoles.admin,
  },
  { path: '/users/:userId', element: <Profile />, auth: authRoles.admin },
];

export default usersRoutes;
