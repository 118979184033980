import Api from './Api';

export const getAllCoupons = async () =>
  Api.http({
    method: 'GET',
    url: '/admin/coupons/all',
  });

export const generateCouponCode = async () =>
  Api.http({ method: 'POST', url: '/admin/coupons/generate-code' });

export const createCoupon = async ({ code, email }) =>
  Api.http({
    method: 'POST',
    url: '/admin/coupons/create',
    body: { code, email },
  });
